import { AssistErrorHandler, ConfigService, CustomRouterStateSerializer, DataProviders, UserRequiredGuard } from '@assist/shared/data';
import { APP_INITIALIZER, ENVIRONMENT_INITIALIZER, ErrorHandler, importProvidersFrom, inject, LOCALE_ID } from '@angular/core';
import { CALENDARS_CONFIGURATION } from '@cue/calendars';
import { QuillModule } from 'ngx-quill';
import { HammerModule } from '@angular/platform-browser';
import { configure, Localization, LOCALIZATIONS } from '@cue/translate';
import { translation } from '@assist/shared/translations';
import { DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NewsDataProviders } from '@assist/news/services';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { QrCodeGuard } from '@assist/portal/services';
import { WebexCallbackGuard } from '@assist/profile/services';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';

import { extProviders } from './build-specifics';
import { PLACEHOLDER_IMAGE_IMAGE } from '@cue/images';
import { LocaleService } from './services';
import { AuthInterceptor } from './interceptors';
import { DatePipe } from '@angular/common';
import { provideStore } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { OrdersDataProviders } from '@assist/orders/services';
import { provideApi, provideSignalRService } from '@cue/api';
import { provideEffects } from '@ngrx/effects';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePipes } from '@assist/ui/pipes';
import { provideGoogleAnalytics } from '@cue/google-analytics';

export const coreConfig = (configService: ConfigService) => ({
  providers: [
    {
      provide: ConfigService,
      useValue: configService,
    },
    provideGoogleAnalytics({
      keyFactory: () => {
        const configService = inject(ConfigService);
        return configService.value.googleAnalyticsEnabled && configService.value.googleAnalyticsKey != null
          ? configService.value.googleAnalyticsKey
          : undefined;
      },
    }),
    provideApi({
      factory: () => configService.value.apiURL,
    }),
    provideSignalRService({
      factory: () => configService.value.clientId,
    }),
    providePipes({
      dateFormat: () => {
        const configService = inject(ConfigService);
        if (configService.value.timelineDefaults.dateFormat != null) {
          return configService.value.timelineDefaults.dateFormat;
        } else {
          return undefined;
        }
      },
      timeFormat: () => {
        const configService = inject(ConfigService);
        if (configService.value.timelineDefaults.timeFormat != null) {
          return configService.value.timelineDefaults.timeFormat;
        } else {
          return undefined;
        }
      },
    }),
    provideRouterStore({
      serializer: CustomRouterStateSerializer,
    }),
    provideStore(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      },
    ),
    provideEffects([]),
    importProvidersFrom(QuillModule.forRoot(), HammerModule, DialogModule),
    NewsDataProviders,
    DataProviders,
    OrdersDataProviders,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    provideRouter(
      [
        {
          path: 'qr/:id',
          canActivate: [QrCodeGuard],
          loadComponent: () => import('@cue/empty').then((x) => x.EmptyComponent),
        },
        {
          path: 'web-ex/callback',
          canActivate: [WebexCallbackGuard],
          loadComponent: () => import('@cue/empty').then((x) => x.EmptyComponent),
        },
        {
          path: '',
          canActivate: [UserRequiredGuard],
          loadChildren: () => import('@assist/portal/routing').then((x) => x.PortalRoutes),
        },
        {
          path: 'account',
          canActivate: [],
          loadChildren: () => import('@assist/account/routing').then((x) => x.AccountRoutes),
        },
        {
          path: '**',
          pathMatch: 'full',
          loadComponent: () => import('./components/not-found.component').then((x) => x.NotFoundComponent),
        },
      ],
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
      }),
    ),

    extProviders,
    // Other provides
    { provide: PLACEHOLDER_IMAGE_IMAGE, useValue: 'assets/images/placeholder.jpg' },
    { provide: ErrorHandler, useClass: AssistErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => new Promise((res) => setTimeout(res)),
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService) => localeService.getLocaleId(),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DatePipe,
    TranslocoPipe,
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue() {
        const translocoService = inject(TranslocoService);
        const localizations = inject(LOCALIZATIONS) as unknown as Localization[][];
        configure(translocoService, localizations);
      },
    },
  ],
});
